/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-10k2lxq js-anim  --anim7 --anim-s5 bg--center --full" anim={"7"} name={"intro"} animS={"5"} style={{"minHeight":"100vh"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/31eca88f6ff04170928f2fb32b9a726d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/b8a9eb8441664043a810663d270dfe4b.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":170}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box fs--154 title-box--invert mt--10" content={"Merry Christmas"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 subtitle-box--invert swpf--uppercase mt--20" content={"and happy New Year"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--40" style={{"maxWidth":684}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Text className="text-box text-box--invert mt--40" style={{"maxWidth":684}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}